<template>
  <div class="TermsView">
    <div class="o-container-gray">
      <md-card class="md-layout-item">

        <md-card-header>
          <SectionTitle :titleContent="messages.title"/>
        </md-card-header>

        <md-card-content>
          <TextBlockDefault :textContent="messages.content.content0"/>
          <TextBlockDefault :textContent="messages.content.content0a"/>
          <TextBlockDefault :textContent="messages.content.content0aBIS"/>
          <TextBlockDefault :textContent="messages.content.content0b"/>
          <TextBlockDefault :textContent="messages.content.content0c"/>
          <TextBlockDefault :textContent="messages.content.content0d"/>
          <TextBlockDefault :textContent="messages.content.content0e"/>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitle1"/>
            <TextBlockDefault :textContent="messages.content.content1"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitle2"/>
            <TextBlockDefault :textContent="messages.content.content2"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitle3"/>
            <TextBlockDefault :textContent="messages.content.content3"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitle4"/>
            <TextBlockDefault :textContent="messages.content.content4"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitle5"/>
            <TextBlockDefault :textContent="messages.content.content5"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitle6"/>
            <TextBlockDefault :textContent="messages.content.content6"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitle7"/>
            <TextBlockDefault :textContent="messages.content.content7"/>
            <TextBlockDefault :textContent="messages.content.content7a"/>
            <TextBlockDefault :textContent="messages.content.content7b"/>
            <TextBlockDefault :textContent="messages.content.content7c"/>
            <TextBlockDefault :textContent="messages.content.content7d"/>
            <TextBlockDefault :textContent="messages.content.content7e"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitleServiceDelivery"/>
            <TextBlockDefault :textContent="messages.content.contentServiceDelivery"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitlePaymentMethods"/>
            <TextBlockDefault :textContent="messages.content.contentPaymentMethods"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitleReturnPolicy"/>
            <TextBlockDefault :textContent="messages.content.contentReturnPolicy"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitleComplaintPolicy"/>
            <TextBlockDefault :textContent="messages.content.contentComplaintPolicy"/>
            <TextBlockDefault :textContent="messages.content.content2ComplaintPolicy"/>
            <TextBlockDefault :textContent="messages.content.content3ComplaintPolicy"/>
            <TextBlockDefault :textContent="messages.content.content4ComplaintPolicy"/>
            <TextBlockDefault :textContent="messages.content.content5ComplaintPolicy"/>
            <TextBlockDefault :textContent="messages.content.content6ComplaintPolicy"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitle8"/>
            <TextBlockDefault :textContent="messages.content.content8"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitle9"/>
            <TextBlockDefault :textContent="messages.content.content9"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitle10"/>
            <TextBlockDefault :textContent="messages.content.content10"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitle11"/>
            <TextBlockDefault :textContent="messages.content.content11"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitle12"/>
            <TextBlockDefault :textContent="messages.content.content12"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitle13"/>
            <TextBlockDefault :textContent="messages.content.content13"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitle14"/>
            <TextBlockDefault :textContent="messages.content.content14"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitle15"/>
            <TextBlockDefault :textContent="messages.content.content15"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitle16"/>
            <TextBlockDefault :textContent="messages.content.content16"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitle17"/>
            <TextBlockDefault :textContent="messages.content.content17"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitle18"/>
            <TextBlockDefault :textContent="messages.content.content18"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitle19"/>
            <TextBlockDefault :textContent="messages.content.content19"/>
          </div>

          <div class="o-container-subsection">
            <SectionSubtitle :titleContent="messages.content.subtitle20"/>
            <TextBlockDefault :textContent="messages.content.content20"/>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import SectionTitle from '@/components/util/SectionTitle';
import SectionSubtitle from '@/components/util/SectionSubtitle';
import TextBlockDefault from '@/components/util/TextBlockDefault';

export default {
  name: 'TermsView',
  components: {
    SectionTitle,
    SectionSubtitle,
    TextBlockDefault
  },
  data() {
    return {
      messages: {
        title: this.$t('termsView.title'),
        content: {
          content0: this.$t('termsView.content.content0'),
          content0a: this.$t('termsView.content.content0a'),
          content0aBIS: this.$t('termsView.content.content0aBIS'),
          content0b: this.$t('termsView.content.content0b'),
          content0c: this.$t('termsView.content.content0c'),
          content0d: this.$t('termsView.content.content0d'),
          content0e: this.$t('termsView.content.content0e'),
          subtitle1: this.$t('termsView.content.subtitle1'),
          content1: this.$t('termsView.content.content1'),
          subtitle2: this.$t('termsView.content.subtitle2'),
          content2: this.$t('termsView.content.content2'),
          subtitle3: this.$t('termsView.content.subtitle3'),
          content3: this.$t('termsView.content.content3'),
          subtitle4: this.$t('termsView.content.subtitle4'),
          content4: this.$t('termsView.content.content4'),
          subtitle5: this.$t('termsView.content.subtitle5'),
          content5: this.$t('termsView.content.content5'),
          subtitle6: this.$t('termsView.content.subtitle6'),
          content6: this.$t('termsView.content.content6'),
          subtitle7: this.$t('termsView.content.subtitle7'),
          content7: this.$t('termsView.content.content7'),
          content7a: this.$t('termsView.content.content7a'),
          content7b: this.$t('termsView.content.content7b'),
          content7c: this.$t('termsView.content.content7c'),
          content7d: this.$t('termsView.content.content7d'),
          content7e: this.$t('termsView.content.content7e'),
          subtitleServiceDelivery: this.$t('termsView.content.subtitleServiceDelivery'),
          contentServiceDelivery: this.$t('termsView.content.contentServiceDelivery'),
          subtitlePaymentMethods: this.$t('termsView.content.subtitlePaymentMethods'),
          contentPaymentMethods: this.$t('termsView.content.contentPaymentMethods'),
          subtitleReturnPolicy: this.$t('termsView.content.subtitleReturnPolicy'),
          contentReturnPolicy: this.$t('termsView.content.contentReturnPolicy'),
          subtitleComplaintPolicy: this.$t('termsView.content.subtitleComplaintPolicy'),
          contentComplaintPolicy: this.$t('termsView.content.contentComplaintPolicy'),
          content2ComplaintPolicy: this.$t('termsView.content.content2ComplaintPolicy'),
          content3ComplaintPolicy: this.$t('termsView.content.content3ComplaintPolicy'),
          content4ComplaintPolicy: this.$t('termsView.content.content4ComplaintPolicy'),
          content5ComplaintPolicy: this.$t('termsView.content.content5ComplaintPolicy'),
          content6ComplaintPolicy: this.$t('termsView.content.content6ComplaintPolicy'),
          subtitle8: this.$t('termsView.content.subtitle8'),
          content8: this.$t('termsView.content.content8'),
          subtitle9: this.$t('termsView.content.subtitle9'),
          content9: this.$t('termsView.content.content9'),
          subtitle10: this.$t('termsView.content.subtitle10'),
          content10: this.$t('termsView.content.content10'),
          subtitle11: this.$t('termsView.content.subtitle11'),
          content11: this.$t('termsView.content.content11'),
          subtitle12: this.$t('termsView.content.subtitle12'),
          content12: this.$t('termsView.content.content12'),
          subtitle13: this.$t('termsView.content.subtitle13'),
          content13: this.$t('termsView.content.content13'),
          subtitle14: this.$t('termsView.content.subtitle14'),
          content14: this.$t('termsView.content.content14'),
          subtitle15: this.$t('termsView.content.subtitle15'),
          content15: this.$t('termsView.content.content15'),
          subtitle16: this.$t('termsView.content.subtitle16'),
          content16: this.$t('termsView.content.content16'),
          subtitle17: this.$t('termsView.content.subtitle17'),
          content17: this.$t('termsView.content.content17'),
          subtitle18: this.$t('termsView.content.subtitle18'),
          content18: this.$t('termsView.content.content18'),
          subtitle19: this.$t('termsView.content.subtitle19'),
          content19: this.$t('termsView.content.content19'),
          subtitle20: this.$t('termsView.content.subtitle20'),
          content20: this.$t('termsView.content.content20'),
        },
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.TermsView {

  .md-list {
    margin: 1.6rem .8rem;
  }

  .SectionSubtitle {
    margin-left: .8rem;
    padding: .8rem;
  }

  .TextBlockDefault {
    margin-left: .8rem;
    padding: .8rem;
  }
}
</style>
